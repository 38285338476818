.mainContainer {
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
}

.headerContainer {
  margin: 0.75rem 1.5625rem 0.75rem 2.1875rem;
  background: #FFFFFF;
}

.companyLogo {
  width: 8.125rem;
  height: 1.50375rem;
}

.dropdownIcon {
  margin-left: 0.5rem;
  width: 0.530625rem;
  height: 0.530625rem;
}

.loggedInUser {
  margin-left: 1.25rem;
}

.userPic {
  width: 3.125rem;
  height: 3.125rem;
}

.signedInContainer {
  margin-left: 0.6875rem;
}

.oddup3 {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.1875rem;

  color: #000000;

}

.signedIn {
  font-weight: 400;
  font-size: 0.625rem;
  line-height: 0.75rem;

  color: #AFAFAF;
  margin-bottom: 0.125rem;
}

.layoutContainer {
  grid-template-columns: 16.8% 83.2%;
}

.colorBar {
  background-color: #F9A61A;
  height: 0.1875rem;
}

.sidebar {
  border-right: 0.0625rem solid #D9D9D9;
  padding: 3.5rem 0.8125rem;
}

.sidebarItemContainer {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.sidebarIcon {
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  margin-right: 0.9375rem;
  color: #71839B;
  ;
}

.sidebarItem {
  display: flex;
  padding: 0.75rem 1.375rem 0.75rem 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #324054;
  cursor: pointer;
}

.sidebarItem:hover {
  background: #EFF6FF;
  border-radius: 0.25rem;
  color: #0093EF;
}

.sidebarItem:hover>.sidebarIcon {
  color: #0093EF;
}

.sidebarActive {
  background: #EFF6FF;
  border-radius: 0.25rem;
  color: #0093EF;
  cursor: unset;
}

.sidebarActive>.sidebarIcon {
  color: #0093EF;
}

.mainBody {
  background-color: #F2F2F2;
  overflow: scroll;
  padding-bottom: 6.25rem;
}

/* .darkMainBody{
  background-color: rgb(26, 27, 30);
  overflow: scroll;
  padding-bottom: 6.25rem;
} */
.themeIcon {
  margin-left: auto;
  margin-right: 0px;
  width: max-content;
  cursor: pointer;
  display: flex;
}

.darkBody {
  .mainBody {
    background-color: rgb(26, 27, 30);
  }

  .sidebarItem {
    color: #beb9b9;
  }

  .sidebarActive {
    background: #1864ab73;
    color: #d0ebff;
  }
  .sidebarIcon{
    color: #beb9b9
  }
  .sidebarActive>.sidebarIcon {
    color: #d0ebff;
  }

  .sidebarItem:hover {
    background: #1864ab73;
    color: #d0ebff;
  }
  .sidebarItem:hover>.sidebarIcon {
    color: #d0ebff;;
  }
  .sidebar {
    border-right: 0.0625rem solid #696868;
    padding: 3.5rem 0.8125rem;
  }
}
.headerText {
  /*font-weight: 500;*/
  font-size: 0.89rem;
  line-height: 1.0625rem;
  /* identical to box height */
  /* text-transform: uppercase; */
  /* color: #000000; */
  font-weight: bold;
}
.sortBtnDiv {
  outline: none;
}
.sortBtnDiv:hover {
  outline: none;
}
.sortBtn {
  width: 0.8rem;
  height: 0.8rem;
  margin-left: 0.9375rem;
  cursor: pointer;
  transition: transform 0.5s;
}

.rotate180 {
  transform: rotate(180deg);
}

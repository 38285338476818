.closeBtn {
    background-color: #53C3E7;
    justify-content: center;
    align-content: center;
}

.modalPopup {
    overflow-y: auto;
    background-color: #FFFFFF;
}

@media (min-width: 1920px) {
    .modalPopup {
        max-width: 1400px;
    }
}

/* funding css start */


.roundCounts {
    background-color: #e7e7e7;
    color: #3a3a3a;
    text-transform: uppercase;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.roundDiv {
    align-items: flex-start;
}

.MarketTabContainer {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    padding: 0px;
    /* gap: 10px; */
    position: absolute;
    width: 100%;
    height: 1067px;
    left: 0;
    top: 0px;
    padding: 0 29px;

}

.MarketTabHeader {
    justify-content: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 0px 0px;
    /* gap: 4; */
    /* align-items: flex-start; */
    /* padding-top: 23px; */
    width: 100%;
    /* height: 105px;   */
    background: #FFFFFF;
    /* border-top: 1px solid #D9D9D9; */
}

.PositionsTabHeader {
    justify-content: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 105px; */
    background: #FFFFFF;
    /* border-top: 1px solid #D9D9D9; */
}

.ClosedPositionsTabHeader {
    justify-content: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 105px; */
    background: #FFFFFF;
    /* border-top: 1px solid #D9D9D9; */
}

.MarketHeadingGrid {
    grid-template-columns: 14% 14% 13.5% 13.8% 12.6% 12% 13.8% 7%;
}

.MarketSubHeadingGrid {
    grid-template-columns: 16.05% 15.3% 30% 13.8% 15.7% 9%;
}

.ordersHeadingGrid {
    grid-template-columns: 22% 15.6% 13.6% 13.8% 10% 19% 7%
}

.CompanyOrdersHeading {
    grid-template-columns: 19% 13% 12.6% 9.6% 12.8% 8% 19.3% 8%
}

.ordersSubHeadingGrid {
    grid-template-columns: 36.8% 9.3%
}

.performanceSubHeadingGrid {
    grid-template-columns: 13.7% 27.53% 13%;
}

.performanceHeadingGrid {
    grid-template-columns: 13.5% 12% 13.4% 11% 14.5% 12% 13.5% 12%;
}

.CompanyPerformanceHeading {
    grid-template-columns: 13.5% 12% 13.4% 11% 14.5% 12% 13.5% 12%;
}

.CompanyOrdersSubHeading {
    grid-template-columns: 32.2% 12%
}

.CompanyPerformanceSubHeading {
    grid-template-columns: 13.7% 27.53% 13%;
}

.PositionsHeadingGrid {
    grid-template-columns: 14% 9% 11% 14.8% 11.9% 11.4% 11.5% 9.4% 8.2%;
}

.PositionsHeading1Grid {
    grid-template-columns: 14% 12.1% 14.9% 14.9% 15.1% 12.6% 9.4% 8.8%;
}

.PositionsHeading2Grid {
    grid-template-columns: 16% 19.8% 16.4% 16.5% 14.9% 9.4% 8.4%;
}

.ClosedPositionsHeadingGrid {
    grid-template-columns: 14% 9% 11% 14.8% 11.9% 11.4% 11.5% 9.4% 8.2%;
}

.ClosedPositionsHeading1Grid {
    grid-template-columns: 14% 12.1% 14.9% 14.9% 15.1% 11.9% 10.1% 8.8%;
}

.ClosedPositionsHeading2Grid {
    grid-template-columns: 12.7% 14.2% 13% 15.7% 12% 17.5% 9.8% 6.8%;
}

.SwappedPositionsHeadingGrid {
    grid-template-columns: 12.7% 14.2% 13% 15.7% 12% 17.5% 9.8% 6.8%;
}

.TransfersHeadingGrid {
    grid-template-columns: 17.8% 14.5% 14.8% 16.8% 23.4% 12.4% 14%;
}

.ColdWalletHeadingGrid {
    grid-template-columns: 19.8% 12.5% 12.8% 14.8% 13.4% 15.6% 10.7%;
}

.WalletHeadingGrid {
    grid-template-columns: 10% 9.5% 12% 12% 13.15% 9.9% 11.8% 8.2% 9.3% 5.5%;
}

.traderWalletHeadingGrid {
    grid-template-columns: 12% 11.4% 13.5% 12.6% 15.4% 10% 11.2% 8% 7%;
}

.StakingHeadingGrid {
    /* grid-template-columns: 20.9% 17.5% 16.5% 17.8% 19% 10%; */
    grid-template-columns: 13% 15% 16% 16.5% 13% 15.3% 9%;
}

.companyStakingHeadingGrid {
    grid-template-columns: 13% 15% 16% 16.5% 13% 15.3% 9%;
}

.PositionsSubHeadingGrid {
    grid-template-columns: 10.3% 12.4% 17.1% 13.3% 13.1% 23.7% 8%;
}

.PositionsSubHeading1Grid {
    grid-template-columns: 13.9% 16.9% 16.8% 17.3% 25% 10%;
}

.PositionsSubHeading2Grid {
    grid-template-columns: 23.1% 19% 19.3% 28.26% 10%;
}

.ClosedPositionsSubHeadingGrid {
    grid-template-columns: 10.3% 12.4% 17.1% 13.3% 13.1% 23.7% 8%;
}

.ClosedPositionsSubHeading1Grid {
    grid-template-columns: 13.9% 16.9% 16.8% 17.3% 25% 10%;
}

.ClosedPositionsSubHeading2Grid {
    grid-template-columns: 15.8% 14.6% 17.7% 33.1% 11%;
}

.SwappedPositionsSubHeadingGrid {
    grid-template-columns: 15.8% 14.6% 17.7% 33.1% 11%;
}

.transferSubHeadingGrid {
    grid-template-columns: 73.3% 24.5%;
}

.coldWalletSubHeadingGrid {
    grid-template-columns: 26.1% 9.5%
}

.WalletSubHeadingGrid {
    grid-template-columns: 14.49% 8.5%;
    margin-left: 20.69%;
}

.StakingSubHeadingGrid {
    grid-template-columns: 68.2% 17% 7%;
    margin-left: 14.35%;
}

.companyStakingSubHeadingGrid {
    grid-template-columns: 68.2% 17% 7%;
    margin-left: 14.35%;
}

.traderWalletSubHeadingGrid {
    grid-template-columns: 17.35% 9.5%;
    margin-left: 24.33%;
}






.rowContainer {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    padding: 0px;
    /* gap: 8px; */
    width: 100%;
    height: 67px;
    /* background: #FFFFFF; */
    border-top: 0.2px solid #f3f3f3;
    /* border-bottom: 0.2px solid #D9D9D9; */


}

.subRowContainer {
    display: grid;
    align-items: center;
    width: 100%;
    height: 67%;
    /* border-top: 0.2px solid #f3f3f3; */
}

.subRowScrollContainer {
    display: grid;
    align-items: center;
    width: 100%;
    height: 290px;
    overflow: scroll;
}

.rowGrid {
    grid-template-columns: 14% 14% 13.5% 13.8% 12.6% 12% 13.8% 7%;
    width: 100%;

}

.PositionsRowGrid {
    grid-template-columns: 14.3% 9% 11% 14.8% 11.5% 11.4% 11.4% 9.1% 8.4%;
    width: 100%;
    align-items: center;

}

.PositionsRow1Grid {
    grid-template-columns: 14.3% 12% 14.9% 14.9% 15.2% 12.1% 9.1% 8.34%;
    width: 100%;
    align-items: center;

}

.PositionsRow2Grid {
    grid-template-columns: 16.4% 19.6% 16.3% 16.4% 14.7% 9.1% 8.4%;
    width: 100%;
    align-items: center;

}

.ClosedPositionsRowGrid {
    grid-template-columns: 14.3% 9% 11% 14.8% 11.5% 11.4% 11.4% 9.1% 8.4%;
    width: 100%;
    align-items: center;

}

.ClosedPositionsRow1Grid {
    grid-template-columns: 14.3% 12% 14.9% 14.9% 15.2% 12.1% 9.1% 8.34%;
    width: 100%;
    align-items: center;

}

.ClosedPositionsRow2Grid {
    grid-template-columns: 13% 14% 13% 15.5% 12% 17.5% 9.4% 7%;
    width: 100%;
    align-items: center;

}

.SwappedPositionsRowGrid {
    grid-template-columns: 13% 14% 13% 15.5% 12% 17.5% 9.4% 7%;
    width: 100%;
    align-items: center;

}
.TransfersRowGrid {
    grid-template-columns: 17.8% 14.5% 14.8% 16.8% 23.4% 12.4% 14%;
    width: 100%;

}

.ColdWalletRowGrid {
    grid-template-columns: 19.6% 12.6% 12.9% 14.8% 13.4% 15.7% 10% 2.5%;
    width: 100%;
}

.PerformanceRowGrid {
    grid-template-columns: 13.5% 12% 13.4% 11% 14.5% 12% 13.5% 9% 3.4%;
    width: 100%;
}

.OrdersRowGrid {
    grid-template-columns: 22% 15.6% 13.6% 13.8% 10% 19.4% 7%;
    width: 100%;
}

.CompanyPerformanceRowGrid {
    grid-template-columns: 13.5% 12% 13.4% 11% 14.5% 12% 13.5% 9% 3.4%;
    width: 100%;
}

.CompanyOrdersRowGrid {
    grid-template-columns: 19% 13% 12.6% 9.6% 12.8% 8% 19.3% 8%;
    width: 100%;
}

.WalletRowGrid {
    grid-template-columns: 10.3% 9.4% 11.9% 11.9% 13.2% 9.8% 11.7% 8.2% 9.2% 5.7%;
    width: 100%;
}

.traderWalletRowGrid {
    grid-template-columns: 12.2% 11.4% 13.4% 12.6% 15.2% 9.9% 11.1% 8% 7.3%;
    width: 100%;
}

.StakingRowGrid {
    grid-template-columns: 13.2% 14.9% 15.9% 16.4% 12.9% 15.2% 10% 3.5%;
    width: 100%;
}

.StakingSubRowGrid {
    grid-template-columns: 11.2% 15.7% 16.8% 17.4% 13.5% 16% 10%;
    width: 100%;
}

.PerformanceSubRowGrid {
    grid-template-columns: 11.8% 12.5% 14.1% 11.5% 15.2% 12.5% 14.3% 9%;
    width: 100%;
}

.companyStakingRowGrid {
    grid-template-columns: 13.2% 14.9% 16% 16.4% 12.8% 15.2% 10% 3.5%;
    width: 100%;
}

.companyStakingSubRowGrid {
    grid-template-columns: 11.2% 15.7% 16.9% 17.3% 13.5% 15.9% 10%;
    width: 100%;
}

.companyPerformanceSubRowGrid {
    grid-template-columns: 11.8% 12.5% 14.1% 11.5% 15.2% 12.5% 14.3% 9%;
    width: 100%;
}

.childRow {
    grid-template-columns: 13% 15.1% 15.9% 16.4% 12.9% 15.2% 10% 3.5%;
    width: 100%; 
}
.chartContainer {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    padding: 0px 25px;
    gap: 27px;
    position: absolute;
    width: -webkit-fill-available;
    height: 753px;
    top: 98%;

}

.miniChartContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 33px 10px;
    gap: 15px;
    width: 315px;
    height: 695px;
    flex: none;
    order: 0;
    flex-grow: 0;
    border-right: 1px solid #D9D9D9;

}

.miniChartBox {
    display: flex;
    align-items: center;
    width: 277px;
    height: 120px;
    background: #FDFEFE;
    border: 1.5px solid #CBD3E0;
    border-radius: 8px;
}

.ActiveMiniChart {
    display: flex;
    align-items: center;
    width: 277px;
    height: 120px;
    background: #FDFEFE;
    border: 1.5px solid #0093EF;
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.textContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 15px;
    gap: 12px;
    width: 59%;
    height: 98px;
    flex: none;
    order: 0;
    flex-grow: 1;
}

.mainChart {
    margin-top: 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 17px 23px;
    width: 100%;
    height: 630px;
    background: #FFFFFF;
    /* border: 1px solid rgba(204, 204, 204, 0.2);
    border-radius: 2px; */
    /* flex: none;
    order: 2; */
}

.AssetsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 5px 0px; */
    position: absolute;
    width: 100%;
    height: 1048px;
    left: 0px;
    /* top: 148%; */

}

.ProfitLossContainer {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 30px 20px 35px;
    margin-bottom: 20px;
    width: 100%;
    height: auto;
    background: #FFFFFF;
    /* border: 1px solid rgba(204, 204, 204, 0.2); */
    /* border-radius: 2px; */
}

.QuantityText {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 14px;
    /* gap: 10px; */
    width: 5.438rem;
    height: 1.858rem;
    background: rgba(0, 147, 239, 0.08);
    border-radius: 10px;
}

.TypeText {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    padding: 4px 14px;
    /* gap: 10px; */
    width: 5.438rem;
    height: 1.858rem;
    border-radius: 10px;
}

.title {
    font-family: 'SF Pro Display';
    /* font-size: 16px; */
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #243E60;
}

.subTitle {
    font-family: 'SF Pro Display';
    /* font-size: 14px; */
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #58667E;
}

/* input[type="checkbox"]{
    border: 2px solid #50AEF4;
    border-radius: 2px;
    appearance: none;
    -webkit-appearance: none;
    height: 12px;
    width: 12px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
input[type="checkbox"]:after{
    border: none;
    content: '\2713';
    /* font-family: 'Font Awesome 5 Free'; */
/* height: 12px; */
/* width: 12px; */
/* background-color: rgb(189, 224, 48); */
/* display: none;
color: #FDFEFE;
font-weight: 900;
align-items: center;
justify-content: center; */
/* } */

/* input[type="checkbox"]:checked{
    /* background-color: rgb(189, 224, 48); */
/* border: none; */
/* }
input[type="checkbox"]:checked:after{
 display: flex; */
/* } */


.shortRow {
    height: auto;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.shortSubRow {
    height: auto;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.performanceRow {
    height: auto;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}

.shadowStyle:hover{
    border: 1px solid rgb(8, 12, 255);
  }

.InputBox {
    width: 7rem;
    border-radius: 3px;
    border: 0.7px solid #58667E;
    padding: 2px 6px 1px 6px;
    color: #3A3A3A;
    font-size: 0.84rem;
    font-style: normal;
    font-weight: 400;
    outline: none;
    background: #FFF;
    font-size: 0.86rem;
}

.InputBox:read-only {
    border-color: transparent;
    background: transparent;
    padding: 0px;
    font-size: 0.95rem;
    border: none;
}

.InputBox:read-only:hover {
    border-color: #58667E;
    background: #FFF;
}

/* .InputBox:first-child{
    margin-top: -5px;
} */
.overViewContainer {
    display: flex;
    padding: 0rem 2rem;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    border-radius: 8px;
    border-radius: 8px;
    border: 1px solid #026DB0;
    /* width: 35.50rem; */
    height: 10rem;
    background-color: #003A6D;
}

.HeadingText {
    color: var(--text-primary-gray-20333, #333);
    font-family: 'SF Pro Display';
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.375rem;
}

.LegendText {
    color: var(--text-primary-gray-20333, #333);
    font-family: 'SF Pro Display';
    font-size: 0.85rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
}

.chartTitle {
    color: var(--text-primary-gray-20333, #333);
    font-family: SF Pro Display;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.375rem;
}

.gridWidth {
    width: calc(100% - 70px);
}

.dark {
    .modalPopup {
        background-color: rgb(26, 27, 30);
    }

    .PositionsTabHeader,
    .ClosedPositionsTabHeader,
    .MarketTabHeader {
        background-color: rgb(26, 27, 30);
    }

    .rowContainer {
        background-color: rgb(26, 27, 30);
        border-top: 1px solid rgb(56, 55, 55);
    }
    .subRowContainer {
        background-color: rgb(36, 36, 36);
    }
}
@tailwind base;
/*@tailwind components;*/
@tailwind utilities;
/*@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,900&display=swap');*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.white-space-pre {
  white-space: pre;
}

.color_0 .mantine-Checkbox-input:checked {
  background-color: #33B1FF;
  border-color: #33B1FF;
}

.color_1 .mantine-Checkbox-input:checked {
  background-color: #3AB011;
  border-color: #3AB011;
}

.color_2 .mantine-Checkbox-input:checked {
  background-color: #FFC83F;
  border-color: #FFC83F;
}

.color_3 .mantine-Checkbox-input:checked {
  background-color: #FF0000;
  border-color: #FF0000;
}

.color_4 .mantine-Checkbox-input:checked {
  background-color: #800080;
  border-color: #800080;
}

.color_5 .mantine-Checkbox-input:checked {
  background-color: #800000;
  border-color: #800000;
}

.color_6 .mantine-Checkbox-input:checked {
  background-color: #FF00FF;
  border-color: #FF00FF;
}

.color_7 .mantine-Checkbox-input:checked {
  background-color: #00FFFF;
  border-color: #00FFFF;
}

.color_8 .mantine-Checkbox-input:checked {
  background-color: #00008B;
  border-color: #00008B;
}

.color_9 .mantine-Checkbox-input:checked {
  background-color: #808000;
  border-color: #808000;
}

.ascending::after {
  content: url('../public/trading-platform/assets/images/DownArrow.svg');
  display: inline-block;
  margin-left: 1em;
  top: -2px;
  position: relative;
}

.descending::after {
  content: url('../public/trading-platform/assets/images/upArrow.svg');
  display: inline-block;
  margin-left: 1em;
  top: -2px;
  position: relative;
}

.capitalization {
  text-transform: capitalize;
}

/* .paddingLeft19.recharts-legend-wrapper {
    left: 36px !important;
  } */
.text-width {
  min-width: 63px;
}

.text-width+span {
  text-align: right;
}


.flashLoading span {
  display: inline-block;
  vertical-align: middle;
  width: 1.2rem;
  height: 1.2rem;
  margin: .39rem;
  background: #5DBB46;
  border-radius: 1rem;
  animation: flashLoading 0.7s infinite alternate;
}


.flashLoading span:nth-of-type(2) {
  background: #FAA71A;
  animation-delay: 0.2s;
}

.flashLoading span:nth-of-type(3) {
  background: #EE2B28;
  animation-delay: 0.4s;
}

.white-borders + .block-borders{
  display: inline-block;
}

@keyframes flashLoading {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.traderRowGrid {
  grid-template-columns: 14.77% 18% 16.78% 24% 18% 10%
}

.chartContainer {
  border-radius: 0.25rem;
  background: #FFF;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
  width: 38.4%;
  height: 15.5625rem;
  flex-shrink: 0
}

.container {
  width: 100%;
  height: 18.5rem;
  flex-shrink: 0;
  background: #FAFAFA;
}

.darkContainer {
  width: 100%;
  height: 18.5rem;
  flex-shrink: 0;
  background: #242424 !important;
  border-top: 1px solid #44403c;

  /* box-shadow: 0px 0px 5px #d8d8d8; */
  .hotColdWallet,
  .chartContainer {
    background-color: black;
    color: #beb9b9;
  }

  .hotColdWallet :first-child {
    color: #beb9b9;
  }
}

.hotColdWallet {
  width: 29.2%;
  height: 15.5625rem;
  flex-shrink: 0;
  border-radius: 0.25rem;
  background: #FFF;
  color: #354052;
  font-family: "SF Pro Display";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.125rem;
}

.HeadingText {
  /* color: #3A3A3A; */
  font-family: "SF Pro Display";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
  white-space: nowrap;
}

.HotColdListingHeader {
  justify-content: stretch;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
}

.HotColdHeadingGrid {
  grid-template-columns: 28% 30% 39.4%;
}

.HotColdHeading {
  color: #98B2D3;
  font-family: "SF Pro Display";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.125rem;
}

.rowContainerMain .rowContainer:first-child {
  border-top: none;
}

.rowContainer {
  display: grid;
  align-items: center;
  padding: 0px;
  width: 100%;
  height: 2.72rem;
}

.HotColdrowGrid {
  grid-template-columns: 28% 30% 39.4%;
  width: 100%;
  align-items: center;
}

.rowText {
  color: #3A3A3A;
  font-family: "SF Pro Display";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.125rem;
}
.FinancialListingContainer {
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: 100%;
  left: 0;
  top: 0px;
  padding: 0 0.5rem;
  border-top: 1px solid #D9D9D9;
}

.FinancialListingHeader {
  justify-content: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 68px;
  /* background: #FFFFFF; */
  
}

.FinancialHeadingGrid {
  grid-template-columns: 13% 8.4% 13.5% 12.6% 13% 8% 9.5% 9% 8% 8%
}

.rowGrid {
  grid-template-columns: 13% 8.7% 13.5% 12.5% 12.6% 8% 9.5% 9% 8% 8%;
  width: 100%;
  align-items: center;
}

.ExchangeListingContainer {
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: 100%;
  /* height: 500px; */
  left: 0;
  top: 45px;
  border-top: 1px solid #D9D9D9;
}

.ExchangeHeadingGrid {
  grid-template-columns: 17% 12% 11.5% 12.6% 10.4% 19% 18%
}

.text {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: normal;
  /* color: #3A3A3A; */
  display: flex;
}

.ExchangeRowGrid {
  grid-template-columns: 16.5% 12.4% 11.5% 12.8% 10.5% 19.5% 18%
}

.CurrencyRowGrid {
  grid-template-columns: 18.2% 11.4% 13.5% 12.6% 14.4% 14% 17%
}

.CurrencyHeadingGrid {
  grid-template-columns: 18.2% 11.4% 13.5% 12.6% 14.4% 14% 17%
}

.CurrencySubHeadingGrid {
  grid-template-columns: 37% 11% 12%
}

.TableHeading {
  color: var(--layout-800, #222);
  font-family: 'SF Pro Display';
  font-size: 16px;
  font-style: normal;
  /* font-weight: 500; */
  line-height: normal;
}

.ListingHeadingText {
  /* color: #3A3A3A; */
  font-family: 'SF Pro Display';
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.BalanceSheetTitle {
  /* color: var(--text-primary-gray-20333, #333); */
  font-family: 'SF Pro Display';
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.375rem;
  /* 122.222% */
}

.ExchangeRowText {
  /* color: #3A3A3A; */
  font-family: 'SF Pro Display';
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.rowContainer {
  display: grid;
  align-items: center;
  padding: 0px;
  width: 100%;
  height: 78px;
  border-top: 0.2px solid #f3f3f3;
}
.TableHeadingBorder{
  
}
.TableHeadingBorder::before {
  background-color: #D9D9D9;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  position: absolute;
}
.TableHeadingBorder::after {
  background-color: #D9D9D9;
  content: "";
  top: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  position: absolute;
  border-top: solid 1px #fff;
}
/*body {*/
/*  padding: 0;*/
/*  margin: 0;*/
/*  font-family: 'Poppins', sans-serif;*/
/*  background: black;*/
/*  height: 100vh;*/
/*  width: 100vw;*/
/*}*/

/*span {*/
/*  position: absolute;*/
/*  left: 50%;*/
/*  top: 50%;*/
/*  transform: translate(-50%, -50%);*/
/*  display: block;*/
/*  color: #cf1b1b;*/
/*  font-size: 124px;*/
/*  letter-spacing: 8px;*/
/*  cursor: pointer;*/
/*}*/

/*span::before {*/
/*  content: "Coming Soon...";*/
/*  position: absolute;*/
/*  color: transparent;*/
/*  background-image: repeating-linear-gradient(*/
/*    45deg,*/
/*    transparent 0,*/
/*    transparent 2px,*/
/*    white 2px,*/
/*    white 4px*/
/*  );*/
/*  -webkit-background-clip: text;*/
/*  top: 0px;*/
/*  left: 0;*/
/*  z-index: -1;*/
/*  transition: 1s;*/
/*}*/

/*span::after {*/
/*  content: "Coming Soon...";*/
/*  position: absolute;*/
/*  color: transparent;*/
/*  !* background-image: repeating-linear-gradient(*/
/*    135deg,*/
/*    transparent 0,*/
/*    transparent 2px,*/
/*    white 2px,*/
/*    white 4px*/
/*  ); *!*/
/*  -webkit-background-clip: text;*/
/*  top: 0px;*/
/*  left: 0px;*/
/*  transition: 1s;*/
/*}*/

/*span:hover:before {*/
/*  top: 10px;*/
/*  left: 10px;*/
/*}*/

/*span:hover:after {*/
/*  top: -10px;*/
/*  left: -10px;*/
/*} */
.textColor {
    color: #3A3A3A;
}

.containerBorder {
    border: 1px solid #CBD3E0;
}

.dark {
    background-color: rgb(26, 27, 30);
    color: #beb9b9 !important;

    tr {
        background-color: black;
        color: #beb9b9;
        border: 1px solid #5c5a5a;
    }

    Input {
        background-color: #2b2a29;
        color: #beb9b9;
        border: 1px solid #44403c;
    }

    .textColor {
        color: #beb9b9;
    }

    .mantine-Select-root {
        Input {
            background-color: black;
            color: #beb9b9;
            border: none;
        }

        div {
            background-color: black;
            color: #beb9b9;
            /* border: 1px solid #44403c; */
        }
    }

    .mantine-DatePicker-input {
        background-color: #2b2a29;
        color: #beb9b9;
        border: 1px solid #44403c;
    }

    .mantine-DatePicker-input:disabled {
        background-color: #2b2a29;
        color: #beb9b9;
        border: 1px solid #44403c;
    }

    .mantine-DatePicker-dropdown {
        background-color: #2b2a29;
    }

    .mantine-DatePicker-calendarHeader {
        background-color: #2b2a29;

        button {
            color: white;
        }
    }

    .mantine-DatePicker-month {
        background-color: #2b2a29;
    }

    .mantine-Tabs-tab {
        color: #beb9b9;

        &:hover {
            background-color: #4c4c4c;
            color: '#d0ebff'
        }
    }
    .mantine-Skeleton-root::after{
        background: #4c4c4c;
    }
    .mantine-Skeleton-root::before{
        background: #8b8b8a;
    }
    .mantine-Checkbox-label {
        color: #beb9b9;
    }
    .mantine-Checkbox-input {
        border: 1px solid #beb9b9;
    }
}
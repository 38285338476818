.CompanyContainer {
    width: 24.5rem;
    max-width: 22.5rem;
    height: 100%;
    flex-shrink: 0;
    border-radius: 7.189px;
    border: 0.09px solid #EFEFEF;
    background: #FFF;
    box-shadow: 0px 0px 1.7972221374511719px 0px rgba(0, 0, 0, 0.08);
}

.HeadingText {
    color: var(--all-text, #3A3A3A);
    font-family: 'SF Pro Display';
    font-size: 1.28rem;
    font-style: normal;
    font-weight: 600;
    line-height: 21.567px;
    display: flex;
    align-items: end;
}

.HeadingTextImg {
    /* margin-top: -10px; */
    height: 3.2rem;
}

.MiniText {
    color: #58667E;
    font-family: 'SF Pro Display';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
}

.Text {
    /* color: #39B118; */
    font-family: 'SF Pro Display';
    font-size: 1.540rem;
    font-style: normal;
    font-weight: 700;
    line-height: 21.567px;
}

.CommingSoonText {
    width: 100%;
    height: 4.7rem;
    flex-shrink: 0;
    color: #58667E;
    text-align: center;
    font-family: 'SF Pro Display';
    font-size: 21.567px;
    font-style: normal;
    font-weight: 700;
    line-height: 21.567px;
}

.lightText {
    justify-content: center;
    display: flex;
    width: 243.985px;
    height: 46.728px;
    flex-shrink: 0;
    color: #CBC7C7;
    text-align: center;
    font-family: 'SF Pro Display';
    font-size: 14.378px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.567px;
}

@media (max-width: 1100px) {
    .MiniText {
        font-size: 12px;
    }

    .Text {
        font-size: 18px;
    }

    .HeadingText {
        font-size: 13px;
    }

    .HeadingTextImg {
        margin-top: 0px;
    }
}

.Loader {
    height: 37px;
    width: 43px;
    border-radius: 3.594px;
    justify-content: center;
    display: flex;
    align-items: center;
    background: #FBFBFB;
}

.LastDays {
    z-index: 0;
    border-radius: 0.3125rem;
    background: #F2F2F2;
    display: inline-flex;
    padding: 0.4375rem 0.875rem;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    gap: 0.625rem;
    color: #898989;
    font-family: 'SF Pro Display';
    font-size: 0.82rem;
    font-weight: 500;
}

.LastDaysBox {
    position: relative;
}

.LastDaysBox:before {
    content: "";
    width: 100%;
    position: absolute;
    left: 0;
    border: 1px dashed #F2F2F2;
    top: 50%;
}
.BalanceSheetTitle{
    color: #58667E;
    font-family: 'SF Pro Display';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
}
.title {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  margin-bottom: 0.125rem;
  color: #3A3A3A;
  margin-right: 0.425rem;
}

.title:hover{
  font-weight: 600;
  font-size: 1.125rem;
  color: #000000;
  margin-right: 0.425rem;
  text-decoration: underline;
}
.subtitle {
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: #898989;
  display: none;
}

.userName{
  font-weight: 600;
  font-size: 0.98rem;
  line-height: 1.3125rem;
  margin-bottom: 0.125rem;
  color: #3A3A3A;
  margin-right: 0.425rem;
}
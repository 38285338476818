.roundIcon {
  min-width: 3.5rem;
  width: 3.5rem;
  min-height: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  border: 0px solid;
}

.paddedShadow {
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
  padding: 0.75rem;
  background: #FFFFFF;
  box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.08);
  border-radius: 0.9375rem;
}

.roundIconBorder:before {
  width: 2.563rem;
  height: 2.563rem;
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;      
  border-radius: 50%;
}





.rowContainer {
  background: #f2f2f2;
  color: #898989;
  border-radius: 0.625rem;
  display: grid;
  padding: 1.25rem;
  align-items: center;
  margin: 0.5rem 0;
}




.headerRow {
  background: none;
}

.mainPage {
  background-color: #f2f2f2;
  min-height: 100vh;
  /* height: 100%; */
}

.headerMainContainer {
  border-bottom: 1px solid #c6c6c6;
}

.tableHeaderContainer {
  box-shadow: 0px 0px 50px -40px rgba(0, 0, 0, 0.1);
  top: 0; /* 76px; */
}

.mainHeight {
  height: calc(100vh - 76px);
  margin-top: 76px;
}


.rightSection {
  /*width: calc(100% - 200px);*/
  /*margin-left: 260px;*/
}

.stickyMainHeaderbox {
  position: sticky;
  top: 0; /* calc(76px + 75px); */
  z-index: 2;
  /* border-bottom: 1px solid #D9D9D9; */
}
.stickyMainHeader {
  z-index: 10;
  height: 58px;
  overflow: hidden;
  /* background-color: #fff; */
  display: flex;
  justify-content: center;
  flex-direction: column;
}


.mainHeightWithWarning {
  margin-top: 106px;
}

@media (max-width: 1023px) {
  .rightSection {
    margin: 0;
  }
}

html {
  height: 100vh;
}

html body {
  overflow-y: auto !important;
  height: calc(100vh - 76px);
}

.modalOverlay {
  position: relative;
}

.modalOverlay:after {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  content: "";
  z-index: 10;
}